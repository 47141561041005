import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import sickkid from '../photos/sickkids.png';
import Image from 'react-bootstrap/Image';
import camdenoffice from '../photos/camdenoffice.jpeg';
import oneidaoffice from '../photos/oneidaoffice.jpeg';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

export default function Contact(){	
		return(

<section class="font-weight-light">

    {/* Contact Us Landing Page */}
    <section>
            <div class="thumbnail text-center" data-aos="fade-up" data-aos-duration="1000">
              <img class="img-responsive" alt="" style={{'width': '100%', 'height': '100%'}} src={sickkid} fluid  />
              <div class="caption">
              <h1 class="text-light font-weight-light" data-aos="fade-up" data-aos-duration="1000"
              style={{'font-family': 'Marck Script'}}>Contact Us</h1>
              </div>
            </div>

      </section> 




{/* Contact Us Banner */}  
 <div class="container-fluid text-center" style={{'background-color': 'rgb(135,206,235)'}} data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
      <br/>
      <h1 class="font-weight-light" style={{'font-family': 'Marck Script'}}>Contact Oneida Pediatric Group</h1>
      <p>Setup an appointment today!</p>
      <p>Kindly call one of our offices today to schedule an appointment 
      with our physicians or feel free to drop by one of our offices for a visit.</p>
        <br/><br/>
    </div>






      {/* Oneida Office Section */}
      <div class="container">
        <br/>
          <div class="row">
            <div class="col-md-6" data-aos="fade-right" data-aos-duration="2000" data-aos-once="true" data-aos-offset="200">
              <h2 class="font-weight-light" style={{'font-family': 'Marck Script', 'color': 'rgb(135,206,235)'}}>Oneida Office</h2>
          <p>
          For any inquries regarding our Oneida Office please follow up with us with one
          of the few contact methods.
          </p>
          <hr/>

          <Image src={oneidaoffice} alt="oneidaoffice" fluid style={{'height': '300px', 'width':'450px', 'border-radius': '5%'}}/>
            <br/><br/>
           <p><i class="fa fa-book" aria-hidden="true" style={{'font-size': '35px', 'color': 'rgb(0,49,82)'}}>
                        </i></p><p> 421 Main St, Oneida, NY </p>

          <p><i class="fa fa-calendar" aria-hidden="true" style={{'font-size': '35px', 'color': 'rgb(135,206,235)'}}>
                </i></p><p> Monday-Friday</p><p> Hours: 9:00am-5:00pm</p>

          <p><i class="fa fa-phone" aria-hidden="true" style={{'font-size': '35px', 'color': 'red'}}>
              </i></p><p> Office: (315) 363-2350</p>
              <p>Fax: (315) 361-1827</p>

        </div>





          {/* Camden Office Section */}
      <div class="col-md-6" data-aos="fade-left" data-aos-duration="2000" data-aos-once="true" data-aos-offset="200">
            <h2 class="font-weight-light" style={{'font-family': 'Marck Script', 'color': 'rgb(135,206,235)'}}>Camden Office</h2>

            <p>
            For any inquries regarding our Camden Office please follow up with us with one
            of the few contact methods.
            </p>

            <hr/>

                <Image src={camdenoffice} alt="camdenoffice" fluid style={{'height': '300px', 'width':'450px', 'border-radius': '5%'}}/>
              <br/><br/>
               <p><i class="fa fa-book" aria-hidden="true" style={{'font-size': '35px', 'color': 'rgb(0,49,82)'}}>
                        </i></p><p> 90 Taberg St, Camden, NY</p>

              <p><i class="fa fa-calendar" aria-hidden="true" style={{'font-size': '35px', 'color': 'rgb(135,206,235)'}}>
                </i></p>
                <p> Monday-Friday</p><p> Hours: 9:00am-5:00pm</p>

             <p><i class="fa fa-phone" aria-hidden="true" style={{'font-size': '35px', 'color': 'red'}}>
                </i></p>
                <p>Office: (315) 245-5483</p>
                <p>Fax: (315) 245-5482</p>
                      
            </div>

          </div>
        </div>

{/* Contact Us Banner */}  
 <div class="container-fluid text-center text-light" style={{'backgroundColor': 'rgb(0,49,82)'}}>
      <br/>
      <h1 class="font-weight-light" style={{ 'font-family': 'Marck Script'}}>Immediate Medical Emergency</h1>
      <h6>For any immediate medical emergency please call one of the following numbers down below!</h6>
      <h5>Poision Control: 1-800-222-1222</h5>
      <h5>Local Ambulance: 9-1-1</h5>
       
        <br/><br/>
      </div>

    </section> 
      
       
		  
		);
	}