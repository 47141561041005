import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';
import Background from '../photos/insurance-back.png';
import Aetna from '../photos/aetna-logo.png';
import Cigna from '../photos/cigna-logo.png';
import Excellus from '../photos/excellus-logo.png';
import Empire from '../photos/empire-logo.png';
import Fidelis from '../photos/fidelis-logo.png';
import Lifetime from '../photos/lifetime-logo.png';
import Medicaid from '../photos/medicaid-logo.png';
import MVP from '../photos/mvp-logo.png';
import Tricare from '../photos/tricare-logo.png';
import UHC from '../photos/uhc-logo.png';
import UMR from '../photos/umr-logo.png';
import UH from '../photos/uh-logo.png';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

const insurancelist = [
{title: 'Aetna', logo: Aetna}, {title: 'Cigna', logo: Cigna }, 
{title: 'Empire Plan', logo: Empire }, {title: 'Excellus', logo: Excellus}, 
{title: 'Fidelis Care', logo: Fidelis},{title: 'Lifetime Benefit Sol.', logo: Lifetime}, 
{title: 'Medicaid NY', logo: Medicaid }, {title: 'MVP', logo: MVP }, 
{title: 'Tricare East 2018', logo: Tricare}, {title: 'UHC Community Plan', logo: UHC }, 
{title: 'UMR', logo: UMR}, {title: 'United Healthcare',logo: UH}
];


export default function Insurance(){	
		return(

          

    <section>
        {/* Creates the home page landing */}
          <section>
                  <div className="thumbnail text-center" data-aos="fade-up" data-aos-duration="1000">
                    <img className="img-responsive" alt="" style={{'width': '100%', 'height': '100%'}} src={Background} fluid/>
                    <div className="caption">
                    <h1 className="text-light" data-aos="fade-up" data-aos-duration="1000"
                     style={{'fontFamily': 'Marck Script', 'fontSize': '2em'}}>Our Accepted Insurance Options</h1>
                    </div>
                  </div>

            </section> 



          {/* Insurance Accepted Banner */}
          <section style={{'background-color': 'rgb(0,49,82)'}} data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
              <br/>
                <div class="text-center">
                  <h2 class="text-center font-weight-light" 
                  style={{'font-family': 'Marck Script', 'color': 'rgb(135,206,235)'}}>
                  At Oneida Pediatric Group we accept the following health insurance brands</h2> 
                </div>
                <br/>
          </section>	

    

    {/* Lists all the types of insurances accepted */}
     <section>
     <br/><br/>
        <div class="row text-center">
            
         {insurancelist.map(insurance => <div class="col-md-4">
          <Image  src={insurance.logo}  style={{'width' : '70%', 'height':'70%'}}roundedcircle fluid/> 
          <br/>
          
          </div>)}

       
      </div>

    </section>  



    </section>
		);
	}