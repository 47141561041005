import React from 'react';
import '../css/HomePage/HomeScreen.css';
import homescreen from '../photos/homescreen.png';
import Image from 'react-bootstrap/Image';
import missionstatement from '../photos/missionstatement.png';
import opgSign from '../photos/opgSign.jpeg';
import rapidTest from '../photos/covid_test.jpg';
import Carousel from 'react-bootstrap/Carousel';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

export default function Home(){ 
    return(
    
<section>
          {/* Creates the home page landing */}
          <section>
                  <div className="thumbnail text-center" data-aos="fade-up" data-aos-duration="1000" >
                    <img className="img-responsive" alt="" style={{'width': '100%', 'height': '100%'}} src={homescreen} fluid/>
                    <div className="caption">
                    <h1 className="text-light" data-aos="fade-up" data-aos-duration="1000" 
                    style={{'fontFamily': 'Marck Script', 'fontSize': '2em'}}>Welcome to the Oneida Pediatric Group</h1>
                    </div>
                  </div>

            </section> 


{/* Landing Image */}
  <section className="bg-dark" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">      
          <div className="row">
            <div className="col-md-6 lg-3" data-aos="fade-right" data-aos-duration="2000" data-aos-once="true">
          <Image src={opgSign} alt="image" style={{'width': '100%', 'height': '100%'}} fluid />
            </div>

          {/* About Section */}
            <div className="col-md-6 "style={{'padding-left': '25px'}} data-aos="fade-left" data-aos-duration="2000" data-aos-once="true">
              <br/>
              <h2 className="text-align-center text-light font-weight-light" 
              style={{'font-family': 'Marck Script'}}>About Us</h2>
              <p className="text-light font-weight-light" >
              We are a private, physician owned general pediatric practice with locations in Oneida
              and Camden NY. Providing quality healthcare from birth to young adulthood which
              has been our honor and privilege for the past 50 years.
              Our goal is to provide excellent medical care as well as providing education and support
              for our families in a professional, empathetic environment where we strive to treat both
              the patient and parents like family.
              </p>
              
              <p className="text-light font-weight-light">Check out our amazing team to learn more about there educational background as well
              as there own journey to becoming a healthcare provider by clicking <a href="ourteam">here</a>
              </p>

          </div>
        </div>
  </section>



{/* Mission Statement */}
  <section style={{'background-color': 'rgb(135,206,235)'}} data-aos="fade-up" data-aos-duration="2000" data-aos-once="true">       

      <div className="row">
                   
            <div class="col-md-6">
	      <br/>
              <h1 className="text-align-center text-dark font-weight-light" style={{'font-family': 'Marck Script'}}>Our Mission Statement</h1>
              <p className="text-dark font-weight">
              As a private pediatric practice, we strive to provide the best possible care to our
              patients by treating our patients like our own family. We seek to gain a deeper understanding 
              about each patients illness and providing a personalized treatment plan.
              
              </p>

              <p className="text-dark font-weight">At our clinic we practice the values of honesty, trustworthiness, respect and reliability in order
              to ensure that our patients recieve the best possible care available.</p>

                    </div>

                    <div className="col-md-6">
                        <Image src={missionstatement} style={{ 'width': '100%', 'height': '100%' }} alt="image" fluid />
                    </div>
        </div>
  </section>

            {/* Rapid Test Statement */}                         
            <section  data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" style={{ 'background-color': 'rgb(165,206,235)' }} >
                <div className="row">
                    <div className="col-md-6">
                        <div  data-aos="fade-right" data-aos-duration="2000" data-aos-once="true">
                            <Image src={rapidTest}  style={{ 'width': '100%', 'height': '100%', 'max-width': '100%' }} alt="image" fluid />
                        </div>
                    </div>
                    <div className="col-md-6">

                        <div  style={{ 'padding-left': '25px' }} data-aos="fade-left" data-aos-duration="2000" data-aos-once="true">
                            <br />
                            <h2 className="text-align-center text-light font-weight-light"
                                style={{ 'font-family': 'Marck Script' }}> Rapid Tests Now Available </h2>
                            <br/>
                            <h4 className="text-align-center text-light font-weight-light"
                                style={{ 'font-family': 'Marck Script' }}> Now offering rapid Covid, Strep, RSV, and Flu tests. </h4>
                           
                            <h4 className="text-align-center text-light font-weight-light"
                                style={{ 'font-family': 'Marck Script' }}> Call during office hours to schedule an appointment. </h4>
                            
                            <br/>
                            
                                
                                <h4 style={{ 'font-family': 'Marck Script' }}>Oneida office: (315)363-2350</h4><br/>
                                <h4 style={{ 'font-family': 'Marck Script' }}>Camden office: (315)245-5483</h4>

                            
			                                                
                        </div>
                    </div>

                </div>
            </section>






{/* Testimonials */}
  <section class="bg-info">
  <br/>
    <div className="container-fluid text-center" style={{'height': '225px'}}>
      <h1 className="font-weight-light" style={{'font-family': 'Marck Script'}}>Testimonial's</h1>
       <Carousel controls={false} indicators={false}>
                <Carousel.Item>
                  <i><p className="text-center" id="test">We've been going to Oneida Pediatrics since my youngest was born (10 years) and 
                  just love the office staff and the physician's here! The physicians listen and 
                  actually care about their patients and we've never been left waiting for an unreasonable 
                  amount of time. After the numerous horror stories I have heard about other pediatricians 
                  offices and experiences that I've had at other medical offices where staff can often be 
                  difficult and unpleasant to deal with, we are SO THANKFUL to have our kiddos with Oneida 
                  Pediatrics!</p></i>
                  <h6>Kathleen Saville</h6>            
                </Carousel.Item>

                 <Carousel.Item>
                  <i><p id="test">The pediatrician's in this office are absolutely amazing. 
                  I don't see how anyone is giving this a less than 4 or 5 star 
                  rating. No matter which pediatrician or doctor you 
                  choose, you're going to find yourself waiting from time to time. The care here is phenomenal, 
                  your babies and children will always be in good hands. Bottom line!</p> </i> 
                  <h6>Courtney Shlotzhauer</h6>            
                </Carousel.Item>

                 <Carousel.Item>           
                  <i><p id="test">Whenever I call to make a appointment they usually 
                  can get my son in that same day. I've never experienced 
                  a long wait in the waiting room which is great because 
                  I have a antsy toddler.</p></i>
                  <h6>Cammeo Lazzaro</h6>              
                </Carousel.Item>
                </Carousel>
             </div>
  </section>









    </section> 
      
       
      
    );
  }