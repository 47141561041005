import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Footer(){	
		return(
    
    <div class="text-center">
	    <nav class="sticky-bottom bg-secondary text-center">
	    <br/>
		  <p class="text-center text-light">©Oneida Pediatric Group 2020 | All Rights Reserved</p>
		  <p class="text-right text-light" style={{'padding-right': '5%'}}>Created by Armen Palvetzian</p>
		  <br/>
		</nav>
    </div>
      
       
		  
		);
	}