import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Components/Home';
import Contact from './Components/Contact';
import OurTeam from './Components/OurTeam';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Insurance from './Components/Insurance';
import Covid from './Components/Covid_19';

 export default function App() {
  return (

    <div>
    <Navbar />
    <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/contact"  component={Contact} />
          <Route path="/ourteam" component={OurTeam} />
          <Route path="/insurance" component={Insurance} />
          <Route path="/covid_19" exact component={Covid} />
        </Switch>
    </Router>
    <Footer />
   </div>
  );
}

