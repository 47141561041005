import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import groupphoto from '../photos/team.png';
import drdanadian from '../photos/photo1.png';
import drtoro from '../photos/photo2.png';
import deborah from '../photos/photo3.jpeg';
import Button from 'react-bootstrap/Button';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

export default function OurTeam(){	
		
    return(
    

  /* Meet Our Team Banner */
    <section class="text-center">
        <div class="thumbnail text-center" data-aos="fade-up" data-aos-duration="1000">
          <img class="img-responsive" alt="staff" style={{'width': '100%', 'height': '100%'}} src={groupphoto} fluid  />
          <div class="caption">
          <h1 class="text-light" data-aos="fade-up" data-aos-duration="1000"
          style={{'font-family': 'Marck Script'}}>Meet Our Team</h1>
          </div>
        </div>


      {/* Our Providers Section */}
      <div class="text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">

        <br/><br/>
        <div class="container text-align-center">       
          <h1 class="font-weight-light text-center" style={{'font-family': 'Marck Script', 'color': 'rgb(135,206,235)'}}>Meet Our Providers</h1><hr/>
          
          <br/><br/>

        {/* Dr. Danadian Bio */}
          <div class="row">         
            <div class="col-md-6">
            <img src={drdanadian} alt="Dr. Danadian" class="img-fluid rounded"/>
              <br/><br/>
              <h4>Dr. Kapriel Danadian, M.D., F.A.A.P</h4>
              <hr/>
              <p class="font-weight-light text-left">Kapriel Danadian, MD is a board certified pediatrician and fellow of the American Academy of
                Pediatrics and has been with the Oneida Pediatric Group in Oneida and Camden NY since 1999.
                He graduated and received his medical degree from the University of Aleppo in Syria in 1987.
                He completed his residency in Pediatrics at Charleston West Virginia. He completed his Pediatric
                Endocrinology Fellowship at the Children’s Hospital of Pittsburgh.
                He is also an adjunct professor for local colleges to prepare the next 
                generation of Physicians Assistants, Nurse Practitioners and Physicians.</p>
                <p class="font-weight-light text-left">
                In 1999 he joined the Oneida Pediatric Group and has been practicing in both the Oneida an
                Camden offices where he is one of the co-owner in the business.
                Dr. Danadian enjoys helping parents learn what to expect raising their children and how to
                promote health and wellness as they grow from infants to adolescents. He also specializes in
                seeing children with Thyroid problems, growth issues, diabetes and childhood obesity.
                He also speaks multiple languages including Arabic and Armenian.
                Dr. Danadian is licensed to practice in New York and is currently taking new patients in both
                offices and accepts multiple insurance plans including Aetna, Blue Cross, Empire, and MVP
                along with many more.</p>
            </div>

          {/* Dr. Toro Banner */}
            <div class="col-md-6">
              <img src={drtoro} alt="Dr. Toro" class="img-fluid rounded"/>
                <br/><br/>
                <h4>Dr. Rezkellah Toro, M.D., F.A.A.P</h4>
                <hr/>
                <p class="font-weight-light text-left">Dr. Rezkallah Toro is a board certified pediatrician and fellow of the American Academy
                    of Pediatrics. He has practiced pediatric and adolescent medicine here since 1991. Dr
                    Toro graduated from the University of Aleppo in 1978. His pediatric residency was at
                    Harlem Hospital Center in New York City. He is one of the co-owners of Oneida Pediatric
                    Group. He is also one of the pediatricians who cares for the newborns of our community
                    at Oneida Healthcare.
                    </p>
                    <p class="font-weight-light text-left">
                    Dr. Toro is licensed to practice in New York and is currently taking new
                    patients in both offices and accepts multiple insurance plans including
                    Aetna, Blue Cross, Empire, and MVP along with many more.</p>
            </div>

          </div>
  
            {/* Deborah Staple Bio */}
            <div class="row">
                <div class="col-md-12">
                <img src={deborah} alt="Deborah Staple" class="img-fluid rounded" style={{'height': '200px', 'width':'200px'}}/>
                  <br/><br/>
                  <h4>Deborah Staple, PA-C</h4>
                  <hr/>
                  <p class="font-weight-light text-left">Deborah Staple, PA-C is a Nationally Certified Physician Assistant in primary care
                      medicine. She is a graduate of Albany Medical College Physician Assistant Program in
                      1986. Promoting the health and wellness of her patients and their families is her
                      passion. As an Adjunct Professor at LeMoyne College (Syracuse NY)and Rochester
                      Institute of Technology (Rochester,NY) she has been teaching future PA’s to strive for
                      excellence in Medicine for the past 25 years. In 1995 she returned to open the Camden
                      office of Oneida Pediatric Group, PC.</p>
              </div>

          

          </div>
          <br/>
        </div>

      </div>

              {/* Contact Us Banner */}
              <div class="container-fluid bg-info text-center">
              <br/>
              <h4>Setup an appointment</h4>
              <p>Contact one of our offices to Setup an appointment today!</p>
                <div class="row">      
                <div class="col-md-12">
                <Button variant="outline-secondary" size="lg"><a href="/contact" class="text-dark" style={{'text-decoration': 'none'}}>Contact Us</a></Button>      
                </div>
                </div>
                <br/><br/>
              </div>
      
    </section>      
		  
		);
	}