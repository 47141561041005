import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import covid from '../photos/covid.png';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

export default function Covid_19(){	
		return(

<section class="font-weight-light">

    {/* Contact Us Landing Page */}
    <section>
            <div class="thumbnail text-center" data-aos="fade-up" data-aos-duration="1000">
              <img class="img-responsive" alt="" style={{'width': '100%', 'height': '100%'}} src={covid} fluid  />
              <div class="caption">
              <h1 class="text-light font-weight-light" data-aos="fade-up" data-aos-duration="1000"
              style={{'font-family': 'Marck Script'}}>Coronavirus</h1>
              </div>
            </div>

      </section> 




{/* Covid-19 Banner */}  
 <div class="container-fluid text-center" style={{'background-color': 'rgb(135,206,235)'}} data-aos="fade-left" data-aos-duration="1000" data-aos-once="true">
      <br/>
      <h1 class="font-weight-light" style={{'font-family': 'Marck Script'}}>Corona Virus/COVID-19</h1>
      <div class="container-fluid col-lg-10">
      <p>
      Oneida Pediatric Group takes the health and safety of our
      patients and staff seriously. In response to the Corona virus
      outbreak, and in order minimize chances of contracting Covid-19,
      we want to address certain precautions and systems that we now
      have in place.
      <br/><br/>
      All children with symptoms of fever, cough and shortness of
      breath will be screened by nurse per CDC guidelines. We do not
      test for the corona virus in our office so if your child has
      symptoms please do not come into the office to be seen but when
      you call our office staff will direct you in what to do next.
      One parent or guardian is allowed in office with patient during this
      time.
      <br/><br/>
      Masks are required to be worn the whole time you are in office by
      children over 2 and all parents and guardians.
      We will not accept walk in patients during this time.
      When you get to the office please remain in your car and call the
      office and the staff will direct you on when to come in.
      Staff will follow strict infection control measures.
      Since the situation we are facing is unprecedented and things are
      changing rapidly we will continue to provide updates for you as
      we are able.

      </p>
      </div>
        <br/><br/>
    </div>


{/* Important Banner */}  
 <div class="container-fluid text-center text-light" style={{'backgroundColor': 'rgb(0,49,82)'}}data-aos="fade-right" data-aos-duration="1000" data-aos-once="true">
      <br/>
      <h1 class="font-weight-light" style={{ 'font-family': 'Marck Script'}}>Important!</h1>
      <div class="container-fluid col-lg-10">
      <p>
      <b>
      We are scheduling in-office sports and well physicals for the
      upcoming new school year. Children need their physicals
      and to be up to date on their immunizations. Call the office to
      speak to a nurse to go over record and schedule an
      appointment if needed.
      </b>
      <br/><br/>
      We are working hard to keep the office safe and clean so that
      children will not fall behind on immunizations. Rooms, door
      handles and surfaces are sanitized after every visit and we are
      separating the well children from the sick children.
      </p>
       </div>
        <br/><br/>
      </div>

    {/* Telemedicine Banner */}  
 <div class="container-fluid text-center text-dark" style={{'backgroundColor': 'rgb(173,216,230)'}} data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
      <br/>
      <h1 class="font-weight-light" style={{ 'font-family': 'Marck Script'}}>Telemedicine</h1>
      <div class="container-fluid col-lg-10">
      
      <p>Please note: We are now offering telemedicine for:</p>
      <ul>
      Behavior Issues<br/>
      Asthma issues<br/>
      Sleep issues<br/>
      Rechecks<br/>
      Some sick concerns
      </ul>
      <br/>
      <p>
      Call our office in Oneida at 315-363-2350 or our
      Camden office at 315-245-5483
      <br/><br/>
      We are working hard to keep the office safe and clean so that
      children will not fall behind on immunizations. Rooms, door
      handles and surfaces are sanitized after every visit and we are
      separating the well children from the sick children.
      </p>
       </div>
        <br/><br/>
      </div>


    {/* Prevention Banner */}  
 <div class="container-fluid text-center text-light" style={{'backgroundColor': 'rgb(78,97,114)'}} data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
      <br/>
      <h1 class="font-weight-light" style={{ 'font-family': 'Marck Script'}}>Prevention</h1>
      <div class="container-fluid col-lg-10">
      <p>
      Prevention is the best way to protect you and your family from
      possible illness. Here are a few tips to help you and your family stay
      healthy.
      <br/><br/>
      *Wear a mask when in public or unable to social distance.
      <br/><br/>
      *Wash your hands thoroughly for at least 20 seconds to remove
      any dirt/bacteria/virus on the skin. If you are unable to wash
      hands then use an alcohol based sanitizer as an alternative.
      <br/><br/>
      *Cover your mouth and nose with a tissue when sneezing or
      coughing. Throw tissue into the trash and wash your hands
      afterwards.
      <br/><br/>
      *Avoid touching eyes, mouth, nose and unwashed hands.
      <br/><br/>
      *Keep children home if they are sick and limit access of visitors.
      <br/><br/>
      *Avoid traveling to high risk areas. You can check for up to date
      information on the CDC website.
      disinfect/sanitize areas that are touched frequently(doors,
      keyboards, cellphones etc.
      <br/><br/>
      Although the Corona Virus is scary, the chance of testing positive
      is low. The New York Department Of Health is prepared for new
      virus outbreaks and has implemented rules and regulations for us
      to follow. We take every patients symptoms and risks into
      consideration in deciding testing and care. We will be diligent with
      getting up to date information and getting it to you. We hope this
      information eases your mind.
      For more information you you can visit the CDC website at
      cdc.gov and they update frequently.
      <br/><br/>
      For further questions you can contact us at above phone
      numbers.

      </p>
       </div>
        <br/><br/>
      </div>


    </section> 
      
       
		  
		);
	}