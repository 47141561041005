import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as ReactBootStrap from "react-bootstrap";



export default function Nav(){	
		return(
<ReactBootStrap.Navbar collapseOnSelect expand="lg" style={{'backgroundColor': 'rgb(145,180,255)'}} variant="dark">
  <ReactBootStrap.Navbar.Brand href="/">Oneida Pediatric Group</ReactBootStrap.Navbar.Brand>
  <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
    <ReactBootStrap.Nav className="ml-auto">
  
      <ReactBootStrap.Nav.Link href="/">Home</ReactBootStrap.Nav.Link>
      <ReactBootStrap.Nav.Link href="/ourteam">Our Team</ReactBootStrap.Nav.Link>
     {/* <ReactBootStrap.Nav.Link href="/insurance">Insurance</ReactBootStrap.Nav.Link>*/}
      <ReactBootStrap.Nav.Link href="/contact">Contact Us</ReactBootStrap.Nav.Link>
      <ReactBootStrap.Nav.Link href="/covid_19">Covid-19</ReactBootStrap.Nav.Link>

    </ReactBootStrap.Nav>
  </ReactBootStrap.Navbar.Collapse>
</ReactBootStrap.Navbar>
        
		);
	}